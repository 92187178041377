import React from 'react';
import { NavLink } from 'react-router-dom';
import { X } from 'lucide-react';
import { navigation } from './navigation';
import { cn } from '../../lib/utils';

interface MobileNavProps {
  open: boolean;
  onClose: () => void;
}

export function MobileNav({ open, onClose }: MobileNavProps) {
  return (
    <div
      className={cn(
        'fixed inset-0 z-50 lg:hidden',
        open ? 'block' : 'hidden'
      )}
    >
      <div className="fixed inset-0 bg-black/75" onClick={onClose} />
      
      <div className="fixed inset-y-0 left-0 w-full max-w-xs bg-white shadow-xl">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <span className="text-xl font-medium text-gray-900">Menu</span>
          <button
            onClick={onClose}
            className="rounded-md p-2 text-gray-500 hover:text-gray-700 hover:bg-gray-100"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <nav className="p-4 space-y-1">
          {navigation.map((item) => {
            const Icon = item.icon;
            return (
              <NavLink
                key={item.name}
                to={item.href}
                end={item.href === '/'}
                onClick={onClose}
                className={({ isActive }) =>
                  cn(
                    'flex items-center px-4 py-3 text-sm font-light rounded-md transition-all duration-300',
                    'hover:bg-gray-50',
                    isActive
                      ? 'bg-gray-50 text-primary shadow-neumorphic-sm'
                      : 'text-gray-700'
                  )
                }
              >
                <Icon className="mr-3 h-5 w-5 flex-shrink-0" />
                {item.name}
              </NavLink>
            );
          })}
        </nav>
      </div>
    </div>
  );
}