import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';
import { initializeDemoUser } from './lib/firebase';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

// Initialize demo user in development
if (import.meta.env.DEV) {
  initializeDemoUser().catch(console.error);
}

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>
);