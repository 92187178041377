import { db } from './firebase';
import { collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import axios from 'axios';

const TWILIO_API_URL = import.meta.env.VITE_TWILIO_API_URL;
const TWILIO_ACCOUNT_SID = import.meta.env.VITE_TWILIO_ACCOUNT_SID;
const TWILIO_AUTH_TOKEN = import.meta.env.VITE_TWILIO_AUTH_TOKEN;
const TWILIO_PHONE_NUMBER = import.meta.env.VITE_TWILIO_PHONE_NUMBER;

interface NotificationPreferences {
  userId: string;
  phoneNumber: string;
  lowStockAlerts: boolean;
  orderUpdates: boolean;
  priceAlerts: boolean;
}

export async function saveNotificationPreferences(preferences: NotificationPreferences) {
  try {
    const prefsRef = collection(db, 'notificationPreferences');
    await addDoc(prefsRef, {
      ...preferences,
      createdAt: new Date(),
    });
  } catch (error) {
    console.error('Error saving notification preferences:', error);
    throw error;
  }
}

export async function getNotificationPreferences(userId: string) {
  try {
    const prefsRef = collection(db, 'notificationPreferences');
    const q = query(prefsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error('Error getting notification preferences:', error);
    throw error;
  }
}

export async function sendSMS(to: string, message: string) {
  try {
    const response = await axios.post(
      `https://api.twilio.com/2010-04-01/Accounts/${TWILIO_ACCOUNT_SID}/Messages.json`,
      new URLSearchParams({
        To: to,
        From: TWILIO_PHONE_NUMBER,
        Body: message,
      }),
      {
        auth: {
          username: TWILIO_ACCOUNT_SID,
          password: TWILIO_AUTH_TOKEN,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error sending SMS:', error);
    throw error;
  }
}

export async function sendLowStockAlert(productName: string, currentStock: number) {
  // Get all users who have enabled low stock alerts
  const prefsRef = collection(db, 'notificationPreferences');
  const q = query(prefsRef, where('lowStockAlerts', '==', true));
  const querySnapshot = await getDocs(q);

  const message = `Low stock alert: ${productName} has only ${currentStock} units remaining.`;

  // Send SMS to each user
  const promises = querySnapshot.docs.map(doc => {
    const { phoneNumber } = doc.data();
    return sendSMS(phoneNumber, message);
  });

  await Promise.all(promises);
}

export async function sendOrderUpdate(orderNumber: string, status: string, phoneNumber: string) {
  const message = `Order Update: Your order #${orderNumber} is now ${status}.`;
  await sendSMS(phoneNumber, message);
}