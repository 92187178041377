import React from 'react';
import { Plus, Filter } from 'lucide-react';
import { Card3D } from '../components/ui/Card3D';
import { OrderList } from '../components/orders/OrderList';
import { OrderStats } from '../components/orders/OrderStats';
import { OrderFilters } from '../components/orders/OrderFilters';

export function Orders() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1>Order Management</h1>
        <button className="flex items-center space-x-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-light text-white hover:bg-indigo-700">
          <Plus className="h-4 w-4" />
          <span>New Order</span>
        </button>
      </div>

      <OrderStats />
      
      <Card3D className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2>Recent Orders</h2>
          <button className="flex items-center space-x-2 text-sm text-gray-600">
            <Filter className="h-4 w-4" />
            <span>Filter</span>
          </button>
        </div>
        
        <OrderFilters />
        <OrderList />
      </Card3D>
    </div>
  );
}