import { db } from './firebase';
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { sendLowStockAlert } from './notifications';

interface ThresholdSettings {
  globalThreshold: number;
  categoryThresholds: {
    [category: string]: number;
  };
  productThresholds: {
    [productId: string]: number;
  };
}

const DEFAULT_THRESHOLD = 10;

export async function getThresholdSettings(userId: string): Promise<ThresholdSettings> {
  try {
    const docRef = doc(db, 'thresholdSettings', userId);
    const docSnap = await getDoc(docRef);
    
    if (docSnap.exists()) {
      return docSnap.data() as ThresholdSettings;
    }
    
    // Initialize with default settings if none exist
    const defaultSettings: ThresholdSettings = {
      globalThreshold: DEFAULT_THRESHOLD,
      categoryThresholds: {},
      productThresholds: {}
    };
    
    await setDoc(docRef, defaultSettings);
    return defaultSettings;
  } catch (error) {
    console.error('Error getting threshold settings:', error);
    throw error;
  }
}

export async function updateThresholdSettings(
  userId: string,
  settings: Partial<ThresholdSettings>
) {
  try {
    const docRef = doc(db, 'thresholdSettings', userId);
    await updateDoc(docRef, settings);
  } catch (error) {
    console.error('Error updating threshold settings:', error);
    throw error;
  }
}

export async function checkStockLevels(
  userId: string,
  product: {
    id: string;
    name: string;
    category: string;
    stock: number;
  }
) {
  const settings = await getThresholdSettings(userId);
  
  // Check thresholds in order of specificity
  const threshold = 
    settings.productThresholds[product.id] ||
    settings.categoryThresholds[product.category] ||
    settings.globalThreshold;
    
  if (product.stock <= threshold) {
    await sendLowStockAlert(product.name, product.stock);
    return true;
  }
  
  return false;
}