import React from 'react';
import { motion } from 'framer-motion';
import { LogOut, Settings, User } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

interface UserDropdownProps {
  onClose: () => void;
}

export function UserDropdown({ onClose }: UserDropdownProps) {
  const { user } = useAuth();

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      className="absolute right-0 mt-2 w-48 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <div className="p-2">
        <div className="px-3 py-2 text-sm text-gray-900">
          {user?.email}
        </div>

        <div className="border-t border-gray-100 my-1" />

        <Link
          to="/settings"
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <Settings className="h-4 w-4 mr-2" />
          Settings
        </Link>

        <Link
          to="/profile"
          className="flex items-center px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md"
          onClick={onClose}
        >
          <User className="h-4 w-4 mr-2" />
          Profile
        </Link>

        <button
          className="flex w-full items-center px-3 py-2 text-sm text-red-600 hover:bg-red-50 rounded-md"
          onClick={() => {
            // Handle logout
            onClose();
          }}
        >
          <LogOut className="h-4 w-4 mr-2" />
          Sign out
        </button>
      </div>
    </motion.div>
  );
}