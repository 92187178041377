import React, { useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { motion } from 'framer-motion';
import { MeshStandardMaterial } from 'three';

function MetallicSphere() {
  const sphereRef = useRef<THREE.Mesh>(null);
  const materialRef = useRef<MeshStandardMaterial>(null);

  useFrame(({ clock }) => {
    if (sphereRef.current) {
      sphereRef.current.rotation.y = clock.getElapsedTime() * 0.5;
    }
    if (materialRef.current) {
      materialRef.current.roughness = Math.sin(clock.getElapsedTime()) * 0.2 + 0.3;
    }
  });

  return (
    <mesh ref={sphereRef}>
      <sphereGeometry args={[1, 64, 64]} />
      <meshStandardMaterial
        ref={materialRef}
        metalness={1}
        roughness={0.2}
        color="#6B21E8"
        envMapIntensity={1}
      />
    </mesh>
  );
}

function NeonRing() {
  const ringRef = useRef<THREE.Mesh>(null);

  useFrame(({ clock }) => {
    if (ringRef.current) {
      ringRef.current.rotation.z = clock.getElapsedTime();
    }
  });

  return (
    <mesh ref={ringRef}>
      <torusGeometry args={[1.5, 0.05, 16, 100]} />
      <meshStandardMaterial
        emissive="#84CC16"
        emissiveIntensity={2}
        color="#84CC16"
        transparent
        opacity={0.8}
      />
    </mesh>
  );
}

function Scene() {
  return (
    <>
      <ambientLight intensity={0.5} />
      <pointLight position={[10, 10, 10]} intensity={1} />
      <MetallicSphere />
      <NeonRing />
      <OrbitControls enableZoom={false} enablePan={false} autoRotate />
    </>
  );
}

export function LoadingSpinner() {
  return (
    <motion.div
      className="h-48 w-48"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Canvas camera={{ position: [0, 0, 5] }}>
        <Scene />
      </Canvas>
    </motion.div>
  );
}