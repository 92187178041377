import React from 'react';
import { Card3D } from '../ui/Card3D';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { TrendingUp } from 'lucide-react';

const data = [
  { category: 'Electronics', growth: 25, revenue: 12500 },
  { category: 'Clothing', growth: 15, revenue: 8900 },
  { category: 'Books', growth: 8, revenue: 4500 },
  { category: 'Home', growth: 20, revenue: 9800 },
  { category: 'Sports', growth: 12, revenue: 6700 },
];

export function TrendAnalysis() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-2 mb-6">
        <TrendingUp className="h-5 w-5 text-indigo-600" />
        <h2>Category Performance</h2>
      </div>

      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="category" />
            <YAxis yAxisId="left" orientation="left" stroke="#6366F1" />
            <YAxis yAxisId="right" orientation="right" stroke="#10B981" />
            <Tooltip />
            <Bar yAxisId="left" dataKey="growth" fill="#6366F1" name="Growth %" />
            <Bar yAxisId="right" dataKey="revenue" fill="#10B981" name="Revenue $" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-4">
        <div className="p-4 bg-indigo-50 rounded-lg">
          <p className="text-sm font-medium text-indigo-900">Top Performer</p>
          <p className="mt-2 text-xl font-medium text-indigo-600">Electronics</p>
          <p className="mt-1 text-sm text-indigo-700">25% growth rate</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <p className="text-sm font-medium text-green-900">Revenue Leader</p>
          <p className="mt-2 text-xl font-medium text-green-600">$12,500</p>
          <p className="mt-1 text-sm text-green-700">Electronics category</p>
        </div>
      </div>
    </Card3D>
  );
}