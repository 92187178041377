import React from 'react';
import { motion } from 'framer-motion';

interface Card3DProps {
  children: React.ReactNode;
  className?: string;
  hover?: boolean;
}

export function Card3D({ children, className = '', hover = true }: Card3DProps) {
  return (
    <motion.div
      className={`neumorphic rounded-lg overflow-hidden ${className}`}
      whileHover={hover ? { 
        scale: 1.02,
        transition: { type: 'spring', stiffness: 300 }
      } : undefined}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  );
}