import React, { useState } from 'react';
import { Card3D } from '../ui/Card3D';
import { ShoppingBag, Check, AlertCircle } from 'lucide-react';

export function ShopifyIntegration() {
  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleConnect = async () => {
    setIsLoading(true);
    // Simulate API connection
    setTimeout(() => {
      setIsConnected(true);
      setIsLoading(false);
    }, 1500);
  };

  return (
    <Card3D className="p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <ShoppingBag className="h-6 w-6 text-indigo-600" />
          <div>
            <h3 className="text-lg font-medium">Shopify Integration</h3>
            <p className="text-sm text-gray-500">Connect your Shopify store</p>
          </div>
        </div>
        
        {isConnected ? (
          <div className="flex items-center text-green-600">
            <Check className="h-5 w-5 mr-2" />
            <span>Connected</span>
          </div>
        ) : (
          <button
            onClick={handleConnect}
            disabled={isLoading}
            className="button-primary"
          >
            {isLoading ? 'Connecting...' : 'Connect Store'}
          </button>
        )}
      </div>

      {isConnected && (
        <div className="mt-4 p-4 bg-green-50 rounded-lg">
          <div className="flex">
            <Check className="h-5 w-5 text-green-400" />
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                Store Connected Successfully
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>Your Shopify store is now connected. You can:</p>
                <ul className="list-disc list-inside mt-1">
                  <li>Sync inventory automatically</li>
                  <li>Import products and orders</li>
                  <li>Manage everything from one dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </Card3D>
  );
}