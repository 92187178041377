import React from 'react';
import { ShopifyIntegration } from '../components/settings/ShopifyIntegration';
import { ApiIntegration } from '../components/settings/ApiIntegration';
import { NotificationSettings } from '../components/settings/NotificationSettings';
import { ThresholdSettings } from '../components/settings/ThresholdSettings';

export function Settings() {
  const handleSaveShopifyApi = async (apiKey: string) => {
    // Implement Shopify API key save logic
    console.log('Saving Shopify API key:', apiKey);
  };

  const handleSaveClaudeApi = async (apiKey: string) => {
    // Implement Claude API key save logic
    console.log('Saving Claude API key:', apiKey);
  };

  return (
    <div className="space-y-6">
      <h1>Settings</h1>

      <div className="space-y-6">
        <ThresholdSettings />
        
        <NotificationSettings />
        
        <ShopifyIntegration />
        
        <ApiIntegration
          title="Claude AI Integration"
          description="Connect Claude AI for demand forecasting"
          apiKey={import.meta.env.VITE_CLAUDE_API_KEY || ''}
          onSave={handleSaveClaudeApi}
        />
        
        <ApiIntegration
          title="Shopify Integration"
          description="Connect your Shopify store"
          apiKey={import.meta.env.VITE_SHOPIFY_ACCESS_TOKEN || ''}
          onSave={handleSaveShopifyApi}
        />
      </div>
    </div>
  );
}