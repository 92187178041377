import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

// Initialize demo user for development
export async function initializeDemoUser() {
  if (import.meta.env.DEV) {
    try {
      // First try to create the demo user
      try {
        await createUserWithEmailAndPassword(auth, 'demo@flowsku.com', 'demo123');
        console.log('Demo user created');
      } catch (error: any) {
        // If user already exists, that's fine
        if (error.code !== 'auth/email-already-in-use') {
          console.error('Error creating demo user:', error);
          throw error;
        }
      }
      
      // Then sign in
      await signInWithEmailAndPassword(auth, 'demo@flowsku.com', 'demo123');
      console.log('Demo user signed in');
    } catch (error) {
      console.error('Error initializing demo user:', error);
      throw error;
    }
  }
}

export { auth, db };
export default app;