import React from 'react';
import { Card3D } from '../ui/Card3D';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Package } from 'lucide-react';

const data = [
  { name: 'In Stock', value: 65 },
  { name: 'Low Stock', value: 25 },
  { name: 'Out of Stock', value: 10 },
];

const COLORS = ['#6366F1', '#FBBF24', '#EF4444'];

export function InventoryMetrics() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-2 mb-6">
        <Package className="h-5 w-5 text-indigo-600" />
        <h2>Inventory Health</h2>
      </div>

      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={100}
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 grid grid-cols-3 gap-4">
        {data.map((item, index) => (
          <div key={item.name} className="text-center">
            <div className="text-2xl font-medium" style={{ color: COLORS[index] }}>
              {item.value}%
            </div>
            <div className="text-sm text-gray-600">{item.name}</div>
          </div>
        ))}
      </div>
    </Card3D>
  );
}