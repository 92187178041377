import React from 'react';
import { AlertTriangle, TrendingUp, RefreshCw } from 'lucide-react';
import { Card3D } from '../ui/Card3D';

interface Prediction {
  sku: string;
  productName: string;
  daysUntilStockout: number;
  confidence: number;
  recommendation: string;
}

// Mock predictions - in production, these would come from Claude API
const predictions: Prediction[] = [
  {
    sku: 'WH-001',
    productName: 'Wireless Headphones',
    daysUntilStockout: 3,
    confidence: 0.92,
    recommendation: 'Order 50 units immediately',
  },
  {
    sku: 'SW-002',
    productName: 'Smart Watch',
    daysUntilStockout: 7,
    confidence: 0.85,
    recommendation: 'Consider ordering within 3 days',
  },
];

export function AIPredictions() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <TrendingUp className="h-5 w-5 text-indigo-600" />
          <h2 className="text-lg font-medium">AI Predictions</h2>
        </div>
        <button className="text-gray-400 hover:text-gray-500">
          <RefreshCw className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-4">
        {predictions.map((prediction) => (
          <div
            key={prediction.sku}
            className={`p-4 rounded-lg ${
              prediction.daysUntilStockout <= 3
                ? 'bg-red-50'
                : 'bg-yellow-50'
            }`}
          >
            <div className="flex items-start">
              <AlertTriangle
                className={`h-5 w-5 ${
                  prediction.daysUntilStockout <= 3
                    ? 'text-red-400'
                    : 'text-yellow-400'
                }`}
              />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-gray-900">
                  {prediction.productName} ({prediction.sku})
                </h3>
                <div className="mt-1">
                  <p className="text-sm text-gray-600">
                    Predicted stockout in {prediction.daysUntilStockout} days
                    <span className="ml-2 text-xs text-gray-500">
                      ({Math.round(prediction.confidence * 100)}% confidence)
                    </span>
                  </p>
                  <p className="mt-1 text-sm font-medium text-gray-900">
                    {prediction.recommendation}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Card3D>
  );
}