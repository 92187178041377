import React, { useState, useEffect } from 'react';
import { AlertTriangle } from 'lucide-react';
import { Card3D } from '../ui/Card3D';
import { useAuth } from '../../contexts/AuthContext';
import { getThresholdSettings, updateThresholdSettings } from '../../lib/inventory';

export function ThresholdSettings() {
  const { user } = useAuth();
  const [settings, setSettings] = useState({
    globalThreshold: 10,
    categoryThresholds: {} as Record<string, number>,
    productThresholds: {} as Record<string, number>
  });
  const [isSaving, setIsSaving] = useState(false);

  // Sample categories - in production, fetch from your product database
  const categories = ['Electronics', 'Clothing', 'Books', 'Home', 'Sports'];

  useEffect(() => {
    if (user) {
      loadSettings();
    }
  }, [user]);

  const loadSettings = async () => {
    if (!user) return;
    try {
      const data = await getThresholdSettings(user.uid);
      setSettings(data);
    } catch (error) {
      console.error('Error loading threshold settings:', error);
    }
  };

  const handleSave = async () => {
    if (!user) return;
    setIsSaving(true);
    try {
      await updateThresholdSettings(user.uid, settings);
    } catch (error) {
      console.error('Error saving threshold settings:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleGlobalThresholdChange = (value: number) => {
    setSettings(prev => ({
      ...prev,
      globalThreshold: value
    }));
  };

  const handleCategoryThresholdChange = (category: string, value: number) => {
    setSettings(prev => ({
      ...prev,
      categoryThresholds: {
        ...prev.categoryThresholds,
        [category]: value
      }
    }));
  };

  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-3 mb-6">
        <AlertTriangle className="h-6 w-6 text-primary" />
        <div>
          <h3 className="text-lg font-medium">Stock Threshold Settings</h3>
          <p className="text-sm text-text-secondary">
            Configure when to receive low stock alerts
          </p>
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-text-primary mb-2">
            Global Threshold
          </label>
          <div className="flex items-center space-x-2">
            <input
              type="number"
              min="0"
              value={settings.globalThreshold}
              onChange={(e) => handleGlobalThresholdChange(parseInt(e.target.value))}
              className="input-modern w-24"
            />
            <span className="text-sm text-text-secondary">units</span>
          </div>
          <p className="mt-1 text-sm text-text-secondary">
            Default threshold for all products
          </p>
        </div>

        <div>
          <h4 className="text-sm font-medium text-text-primary mb-4">
            Category Thresholds
          </h4>
          <div className="space-y-4">
            {categories.map((category) => (
              <div key={category} className="flex items-center justify-between">
                <span className="text-sm text-text-primary">{category}</span>
                <div className="flex items-center space-x-2">
                  <input
                    type="number"
                    min="0"
                    value={settings.categoryThresholds[category] || ''}
                    onChange={(e) => handleCategoryThresholdChange(
                      category,
                      parseInt(e.target.value)
                    )}
                    placeholder={settings.globalThreshold.toString()}
                    className="input-modern w-24"
                  />
                  <span className="text-sm text-text-secondary">units</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="button-primary"
          >
            {isSaving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </Card3D>
  );
}