import React from 'react';
import { Card3D } from '../ui/Card3D';
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Users } from 'lucide-react';

const data = [
  { name: 'VIP', value: 15, color: '#6366F1' },
  { name: 'Regular', value: 45, color: '#10B981' },
  { name: 'Occasional', value: 25, color: '#F59E0B' },
  { name: 'New', value: 15, color: '#3B82F6' },
];

export function CustomerSegments() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-2 mb-6">
        <Users className="h-5 w-5 text-indigo-600" />
        <h2>Customer Segments</h2>
      </div>

      <div className="h-64">
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={60}
              outerRadius={80}
              paddingAngle={5}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 space-y-4">
        {data.map((segment) => (
          <div key={segment.name} className="flex items-center justify-between">
            <div className="flex items-center">
              <div
                className="w-3 h-3 rounded-full mr-2"
                style={{ backgroundColor: segment.color }}
              />
              <span className="text-sm text-gray-600">{segment.name}</span>
            </div>
            <span className="text-sm font-medium text-gray-900">
              {segment.value}%
            </span>
          </div>
        ))}
      </div>
    </Card3D>
  );
}