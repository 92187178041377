import React from 'react';
import { Card3D } from '../ui/Card3D';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DollarSign } from 'lucide-react';

const data = [
  { date: '2024-01', sales: 4000, target: 3800 },
  { date: '2024-02', sales: 3500, target: 3900 },
  { date: '2024-03', sales: 4200, target: 4000 },
  { date: '2024-04', sales: 5100, target: 4100 },
  { date: '2024-05', sales: 4800, target: 4200 },
  { date: '2024-06', sales: 5500, target: 4300 },
];

export function SalesOverview() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-2 mb-6">
        <DollarSign className="h-5 w-5 text-indigo-600" />
        <h2>Sales Overview</h2>
      </div>
      
      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Line 
              type="monotone" 
              dataKey="sales" 
              stroke="#6366F1" 
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            <Line 
              type="monotone" 
              dataKey="target" 
              stroke="#E11D48" 
              strokeDasharray="5 5"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-4">
        <div className="p-4 bg-indigo-50 rounded-lg">
          <p className="text-sm font-medium text-indigo-900">Total Sales</p>
          <p className="mt-2 text-2xl font-medium text-indigo-600">$27,100</p>
          <p className="mt-1 text-sm text-indigo-700">+15.3% from last month</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <p className="text-sm font-medium text-green-900">Target Achievement</p>
          <p className="mt-2 text-2xl font-medium text-green-600">127.8%</p>
          <p className="mt-1 text-sm text-green-700">Above monthly target</p>
        </div>
      </div>
    </Card3D>
  );
}