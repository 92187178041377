import React from 'react';

export function OrderFilters() {
  return (
    <div className="mb-6 grid grid-cols-1 gap-4 sm:grid-cols-4">
      <select className="rounded-md border-gray-300 text-sm">
        <option>All Statuses</option>
        <option>Pending</option>
        <option>Processing</option>
        <option>Shipped</option>
        <option>Delivered</option>
      </select>

      <select className="rounded-md border-gray-300 text-sm">
        <option>All Time</option>
        <option>Last 24 Hours</option>
        <option>Last 7 Days</option>
        <option>Last 30 Days</option>
        <option>Last 90 Days</option>
      </select>

      <select className="rounded-md border-gray-300 text-sm">
        <option>All Payment Status</option>
        <option>Paid</option>
        <option>Pending</option>
        <option>Failed</option>
      </select>

      <select className="rounded-md border-gray-300 text-sm">
        <option>Sort By: Latest</option>
        <option>Sort By: Oldest</option>
        <option>Sort By: Amount (High to Low)</option>
        <option>Sort By: Amount (Low to High)</option>
      </select>
    </div>
  );
}