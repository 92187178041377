import React, { useState } from 'react';
import { Card3D } from '../ui/Card3D';
import { Settings, Check } from 'lucide-react';

interface ApiIntegrationProps {
  title: string;
  description: string;
  apiKey: string;
  onSave: (apiKey: string) => void;
}

export function ApiIntegration({ title, description, apiKey, onSave }: ApiIntegrationProps) {
  const [key, setKey] = useState(apiKey);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await onSave(key);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving API key:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card3D className="p-6">
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-3">
          <Settings className="h-6 w-6 text-indigo-600" />
          <div>
            <h3 className="text-lg font-medium">{title}</h3>
            <p className="text-sm text-gray-500">{description}</p>
          </div>
        </div>
        
        {!isEditing && apiKey && (
          <div className="flex items-center text-green-600">
            <Check className="h-5 w-5 mr-2" />
            <span>Connected</span>
          </div>
        )}
      </div>

      <div className="mt-4">
        {isEditing ? (
          <div className="space-y-4">
            <input
              type="text"
              value={key}
              onChange={(e) => setKey(e.target.value)}
              className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Enter API key"
            />
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setIsEditing(false)}
                className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleSave}
                disabled={isSaving}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 disabled:opacity-50"
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="text-indigo-600 hover:text-indigo-700 text-sm font-medium"
          >
            {apiKey ? 'Update API Key' : 'Add API Key'}
          </button>
        )}
      </div>
    </Card3D>
  );
}