import React, { useState, useEffect } from 'react';
import { Bell } from 'lucide-react';
import { Card3D } from '../ui/Card3D';
import { useAuth } from '../../contexts/AuthContext';
import { saveNotificationPreferences, getNotificationPreferences } from '../../lib/notifications';

export function NotificationSettings() {
  const { user } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [preferences, setPreferences] = useState({
    lowStockAlerts: true,
    orderUpdates: true,
    priceAlerts: false,
  });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (user) {
      loadPreferences();
    }
  }, [user]);

  const loadPreferences = async () => {
    if (!user) return;
    try {
      const prefs = await getNotificationPreferences(user.uid);
      if (prefs.length > 0) {
        const { phoneNumber, ...settings } = prefs[0];
        setPhoneNumber(phoneNumber);
        setPreferences(settings);
      }
    } catch (error) {
      console.error('Error loading notification preferences:', error);
    }
  };

  const handleSave = async () => {
    if (!user) return;
    setIsSaving(true);
    try {
      await saveNotificationPreferences({
        userId: user.uid,
        phoneNumber,
        ...preferences,
      });
    } catch (error) {
      console.error('Error saving notification preferences:', error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-3 mb-6">
        <Bell className="h-6 w-6 text-primary" />
        <div>
          <h3 className="text-lg font-medium">Notification Settings</h3>
          <p className="text-sm text-text-secondary">Configure your SMS notifications</p>
        </div>
      </div>

      <div className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-text-primary mb-2">
            Phone Number
          </label>
          <input
            type="tel"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="input-modern w-full"
            placeholder="+1234567890"
          />
        </div>

        <div className="space-y-4">
          <label className="flex items-center">
            <input
              type="checkbox"
              checked={preferences.lowStockAlerts}
              onChange={(e) => setPreferences(prev => ({
                ...prev,
                lowStockAlerts: e.target.checked
              }))}
              className="rounded text-primary"
            />
            <span className="ml-2 text-sm text-text-primary">Low stock alerts</span>
          </label>

          <label className="flex items-center">
            <input
              type="checkbox"
              checked={preferences.orderUpdates}
              onChange={(e) => setPreferences(prev => ({
                ...prev,
                orderUpdates: e.target.checked
              }))}
              className="rounded text-primary"
            />
            <span className="ml-2 text-sm text-text-primary">Order updates</span>
          </label>

          <label className="flex items-center">
            <input
              type="checkbox"
              checked={preferences.priceAlerts}
              onChange={(e) => setPreferences(prev => ({
                ...prev,
                priceAlerts: e.target.checked
              }))}
              className="rounded text-primary"
            />
            <span className="ml-2 text-sm text-text-primary">Price change alerts</span>
          </label>
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleSave}
            disabled={isSaving}
            className="button-primary"
          >
            {isSaving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </Card3D>
  );
}