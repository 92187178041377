import React from 'react';
import { Package2 } from 'lucide-react';
import { Card3D } from '../ui/Card3D';
import { formatCurrency } from '../../lib/utils';

interface ProductCardProps {
  product: {
    id: number;
    name: string;
    sku: string;
    stock: number;
    price: number;
    category: string;
  };
}

export function ProductCard({ product }: ProductCardProps) {
  return (
    <Card3D className="p-6">
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <h3 className="text-lg font-medium text-gray-900">{product.name}</h3>
          <p className="mt-1 text-sm text-gray-500">SKU: {product.sku}</p>
        </div>
        <Package2 className="h-6 w-6 text-indigo-600" />
      </div>
      <div className="mt-4 grid grid-cols-2 gap-4">
        <div>
          <p className="text-sm text-gray-500">Stock</p>
          <p className="mt-1 text-lg font-medium text-gray-900">{product.stock}</p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Price</p>
          <p className="mt-1 text-lg font-medium text-gray-900">{formatCurrency(product.price)}</p>
        </div>
      </div>
      <div className="mt-4">
        <span className="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800">
          {product.category}
        </span>
      </div>
    </Card3D>
  );
}