import React from 'react';
import { Card3D } from '../ui/Card3D';
import { ShoppingBag, TrendingUp, Clock, CheckCircle } from 'lucide-react';

const stats = [
  {
    name: 'Total Orders',
    value: '156',
    change: '+12.5%',
    icon: ShoppingBag,
    color: 'text-indigo-600',
    bg: 'bg-indigo-50',
  },
  {
    name: 'Order Value',
    value: '$12,789',
    change: '+8.2%',
    icon: TrendingUp,
    color: 'text-green-600',
    bg: 'bg-green-50',
  },
  {
    name: 'Pending',
    value: '23',
    change: '-2.1%',
    icon: Clock,
    color: 'text-yellow-600',
    bg: 'bg-yellow-50',
  },
  {
    name: 'Completed',
    value: '89',
    change: '+18.4%',
    icon: CheckCircle,
    color: 'text-blue-600',
    bg: 'bg-blue-50',
  },
];

export function OrderStats() {
  return (
    <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">
      {stats.map((stat) => {
        const Icon = stat.icon;
        return (
          <Card3D key={stat.name} className="p-6">
            <div className="flex items-center">
              <div className={`${stat.bg} rounded-lg p-3`}>
                <Icon className={`h-6 w-6 ${stat.color}`} />
              </div>
              <div className="ml-4">
                <p className="text-sm font-medium text-gray-500">{stat.name}</p>
                <div className="flex items-baseline">
                  <p className="text-2xl font-medium text-gray-900">
                    {stat.value}
                  </p>
                  <p className="ml-2 text-sm text-green-600">{stat.change}</p>
                </div>
              </div>
            </div>
          </Card3D>
        );
      })}
    </div>
  );
}