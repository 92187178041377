import React, { useState } from 'react';
import { Bell, X, Settings } from 'lucide-react';
import { Card3D } from '../ui/Card3D';
import { formatDistanceToNow } from 'date-fns';

interface Notification {
  id: string;
  title: string;
  message: string;
  type: 'info' | 'warning' | 'critical';
  timestamp: Date;
  read: boolean;
}

// Mock notifications - in production, these would come from Firebase
const initialNotifications: Notification[] = [
  {
    id: '1',
    title: 'Low Stock Alert',
    message: 'Wireless Headphones (WH-001) stock below threshold',
    type: 'critical',
    timestamp: new Date(Date.now() - 1000 * 60 * 30), // 30 minutes ago
    read: false,
  },
  {
    id: '2',
    title: 'Price Change Detected',
    message: 'Competitor price change detected for Smart Watch (SW-002)',
    type: 'info',
    timestamp: new Date(Date.now() - 1000 * 60 * 60 * 2), // 2 hours ago
    read: false,
  },
];

export function NotificationCenter() {
  const [notifications, setNotifications] = useState(initialNotifications);
  const [showSettings, setShowSettings] = useState(false);

  const markAsRead = (id: string) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id
          ? { ...notification, read: true }
          : notification
      )
    );
  };

  const removeNotification = (id: string) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.id !== id)
    );
  };

  const getTypeStyles = (type: Notification['type']) => {
    switch (type) {
      case 'critical':
        return 'bg-red-50 border-red-100';
      case 'warning':
        return 'bg-yellow-50 border-yellow-100';
      default:
        return 'bg-blue-50 border-blue-100';
    }
  };

  return (
    <Card3D className="p-6">
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <Bell className="h-5 w-5 text-indigo-600" />
          <h2 className="text-lg font-medium">Notifications</h2>
        </div>
        <button
          onClick={() => setShowSettings(!showSettings)}
          className="text-gray-400 hover:text-gray-500"
        >
          <Settings className="h-5 w-5" />
        </button>
      </div>

      <div className="space-y-4">
        {notifications.map((notification) => (
          <div
            key={notification.id}
            className={`p-4 rounded-lg border ${getTypeStyles(
              notification.type
            )} ${notification.read ? 'opacity-75' : ''}`}
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <h3 className="text-sm font-medium text-gray-900">
                  {notification.title}
                </h3>
                <p className="mt-1 text-sm text-gray-600">
                  {notification.message}
                </p>
                <p className="mt-1 text-xs text-gray-500">
                  {formatDistanceToNow(notification.timestamp, {
                    addSuffix: true,
                  })}
                </p>
              </div>
              <div className="ml-4 flex space-x-2">
                {!notification.read && (
                  <button
                    onClick={() => markAsRead(notification.id)}
                    className="text-sm text-indigo-600 hover:text-indigo-500"
                  >
                    Mark as read
                  </button>
                )}
                <button
                  onClick={() => removeNotification(notification.id)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showSettings && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg">
          <h3 className="text-sm font-medium text-gray-900 mb-2">
            Notification Settings
          </h3>
          <div className="space-y-2">
            <label className="flex items-center">
              <input type="checkbox" className="rounded text-indigo-600" />
              <span className="ml-2 text-sm text-gray-600">
                Low stock alerts
              </span>
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="rounded text-indigo-600" />
              <span className="ml-2 text-sm text-gray-600">
                Price change alerts
              </span>
            </label>
            <label className="flex items-center">
              <input type="checkbox" className="rounded text-indigo-600" />
              <span className="ml-2 text-sm text-gray-600">
                Demand forecast alerts
              </span>
            </label>
          </div>
        </div>
      )}
    </Card3D>
  );
}