import React from 'react';
import { Plus } from 'lucide-react';
import { LoadingSpinner } from '../components/ui/LoadingSpinner';
import { SearchBar } from '../components/inventory/SearchBar';
import { ProductCard } from '../components/inventory/ProductCard';

const products = [
  { id: 1, name: 'Wireless Headphones', sku: 'WH-001', stock: 145, price: 99.99, category: 'Electronics' },
  { id: 2, name: 'Smart Watch', sku: 'SW-002', stock: 89, price: 199.99, category: 'Electronics' },
  { id: 3, name: 'Running Shoes', sku: 'RS-003', stock: 234, price: 79.99, category: 'Sports' },
];

export function Inventory() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState('');

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handleFilter = () => {
    // Implement filter logic
    console.log('Filter clicked');
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1>Inventory Management</h1>
        <button className="flex items-center space-x-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-light text-white hover:bg-indigo-700">
          <Plus className="h-4 w-4" />
          <span>Add Product</span>
        </button>
      </div>

      <SearchBar onSearch={handleSearch} onFilter={handleFilter} />

      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
          {products.map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      )}
    </div>
  );
}