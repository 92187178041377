import React from 'react';
import { Card3D } from '../ui/Card3D';
import { Users } from 'lucide-react';

const metrics = [
  { label: 'Active Customers', value: '1,234', change: '+12.3%' },
  { label: 'Average Order Value', value: '$89.50', change: '+5.7%' },
  { label: 'Customer Retention', value: '78.2%', change: '+2.1%' },
  { label: 'New Customers', value: '145', change: '+8.9%' },
];

export function CustomerInsights() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center space-x-2 mb-6">
        <Users className="h-5 w-5 text-indigo-600" />
        <h2>Customer Insights</h2>
      </div>

      <div className="space-y-4">
        {metrics.map((metric) => (
          <div key={metric.label} className="p-4 bg-gray-50 rounded-lg">
            <div className="text-sm text-gray-600">{metric.label}</div>
            <div className="mt-1 flex items-baseline">
              <div className="text-2xl font-medium text-gray-900">
                {metric.value}
              </div>
              <div className="ml-2 text-sm text-green-600">{metric.change}</div>
            </div>
          </div>
        ))}
      </div>
    </Card3D>
  );
}