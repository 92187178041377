import React from 'react';
import { DollarSign, Package, ShoppingCart, TrendingUp } from 'lucide-react';
import type { StatItem } from '../types/stats';
import { InventoryChart } from '../components/dashboard/InventoryChart';
import { PerformanceMetrics } from '../components/dashboard/PerformanceMetrics';
import { BulkInventoryUpdate } from '../components/dashboard/BulkInventoryUpdate';
import { AIPredictions } from '../components/dashboard/AIPredictions';
import { NotificationCenter } from '../components/dashboard/NotificationCenter';

const stats: StatItem[] = [
  { name: 'Total Revenue', value: '$45,231', icon: DollarSign, change: '+20.1%' },
  { name: 'Products', value: '2,345', icon: Package, change: '+15.1%' },
  { name: 'Orders', value: '345', icon: ShoppingCart, change: '+12.5%' },
  { name: 'Conversion Rate', value: '3.15%', icon: TrendingUp, change: '+4.75%' },
];

export function Dashboard() {
  return (
    <div className="min-h-screen bg-background">
      <div className="space-y-6 p-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <h1 className="text-2xl sm:text-3xl">Dashboard</h1>
          <button className="button-primary">
            Generate Report
          </button>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
          {stats.map((stat) => {
            const Icon = stat.icon;
            return (
              <div
                key={stat.name}
                className="neumorphic p-6"
              >
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <Icon className="h-6 w-6 text-primary" />
                  </div>
                  <div className="ml-5 w-0 flex-1">
                    <dl>
                      <dt className="truncate text-sm font-light text-text-secondary">
                        {stat.name}
                      </dt>
                      <dd className="flex items-baseline">
                        <div className="text-xl sm:text-2xl font-medium text-text-primary">
                          {stat.value}
                        </div>
                        <div className="ml-2 flex items-baseline text-sm font-medium text-success">
                          {stat.change}
                        </div>
                      </dd>
                    </dl>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="neumorphic p-6">
            <h2 className="text-lg sm:text-xl font-medium mb-4">Inventory Levels</h2>
            <InventoryChart />
          </div>
          <div className="neumorphic p-6">
            <h2 className="text-lg sm:text-xl font-medium mb-4">Category Performance</h2>
            <PerformanceMetrics />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <AIPredictions />
          <NotificationCenter />
        </div>

        <div className="neumorphic p-6">
          <h2 className="text-lg sm:text-xl font-medium mb-4">Bulk Inventory Update</h2>
          <BulkInventoryUpdate />
        </div>
      </div>
    </div>
  );
}