import React from 'react';
import { motion } from 'framer-motion';
import { Bell, X } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

interface NotificationDropdownProps {
  onClose: () => void;
}

export function NotificationDropdown({ onClose }: NotificationDropdownProps) {
  const notifications = [
    {
      id: '1',
      title: 'Low Stock Alert',
      message: 'Wireless Headphones stock below threshold',
      time: new Date(Date.now() - 1000 * 60 * 30),
      type: 'warning'
    },
    {
      id: '2',
      title: 'New Order',
      message: 'Order #1234 received',
      time: new Date(Date.now() - 1000 * 60 * 60),
      type: 'info'
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 10 }}
      className="absolute right-0 mt-2 w-80 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
    >
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-medium">Notifications</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className="flex items-start space-x-3 p-3 rounded-lg bg-gray-50"
            >
              <Bell className="h-5 w-5 text-primary flex-shrink-0 mt-0.5" />
              <div className="flex-1 min-w-0">
                <p className="text-sm font-medium text-gray-900">
                  {notification.title}
                </p>
                <p className="text-sm text-gray-500">{notification.message}</p>
                <p className="text-xs text-gray-400 mt-1">
                  {formatDistanceToNow(notification.time, { addSuffix: true })}
                </p>
              </div>
            </div>
          ))}
        </div>

        <button
          className="mt-4 w-full text-center text-sm text-primary hover:text-primary-dark"
        >
          View all notifications
        </button>
      </div>
    </motion.div>
  );
}