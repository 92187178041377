import React from 'react';
import { TrendingUp, RefreshCw } from 'lucide-react';
import { Card3D } from '../ui/Card3D';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const predictions = [
  { month: 'Jan', actual: 4000, predicted: 4400 },
  { month: 'Feb', actual: 3000, predicted: 3200 },
  { month: 'Mar', actual: 2000, predicted: 2400 },
  { month: 'Apr', actual: 2780, predicted: 2900 },
  { month: 'May', actual: 1890, predicted: 2100 },
  { month: 'Jun', actual: null, predicted: 3200 },
  { month: 'Jul', actual: null, predicted: 3800 },
];

export function PredictiveAnalytics() {
  return (
    <Card3D className="p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <TrendingUp className="h-5 w-5 text-indigo-600" />
          <h2>AI-Powered Predictions</h2>
        </div>
        <button className="text-gray-400 hover:text-gray-500">
          <RefreshCw className="h-5 w-5" />
        </button>
      </div>

      <div className="h-80">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={predictions}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="actual"
              stroke="#6366F1"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
            <Line
              type="monotone"
              dataKey="predicted"
              stroke="#818CF8"
              strokeDasharray="5 5"
              strokeWidth={2}
              dot={{ r: 4 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      <div className="mt-6 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="p-4 bg-indigo-50 rounded-lg">
          <h3 className="text-sm font-medium text-indigo-900">Projected Growth</h3>
          <p className="mt-2 text-2xl font-medium text-indigo-600">+24.3%</p>
          <p className="mt-1 text-sm text-indigo-700">Expected in next quarter</p>
        </div>
        <div className="p-4 bg-green-50 rounded-lg">
          <h3 className="text-sm font-medium text-green-900">Forecast Accuracy</h3>
          <p className="mt-2 text-2xl font-medium text-green-600">92.8%</p>
          <p className="mt-1 text-sm text-green-700">Based on historical data</p>
        </div>
      </div>
    </Card3D>
  );
}