import React from 'react';
import { NavLink } from 'react-router-dom';
import { navigation } from './navigation';
import { cn } from '../../lib/utils';

interface SidebarProps {
  mobile?: boolean;
  onClose?: () => void;
}

export function Sidebar({ mobile, onClose }: SidebarProps) {
  const handleClick = () => {
    if (mobile && onClose) {
      onClose();
    }
  };

  return (
    <aside className={cn(
      "w-64 flex-shrink-0 border-r border-dark-400 bg-dark-300",
      mobile ? "h-full" : "hidden lg:block"
    )}>
      <nav className="flex h-full flex-col">
        <div className="space-y-1 p-4">
          {navigation.map((item) => {
            const Icon = item.icon;
            return (
              <NavLink
                key={item.name}
                to={item.href}
                end={item.href === '/'}
                onClick={handleClick}
                className={({ isActive }) =>
                  cn(
                    'group flex items-center px-3 py-2 text-sm font-light rounded-md transition-all duration-300',
                    'hover:shadow-[0_0_15px_rgba(132,204,22,0.3)] hover:bg-dark-400/50',
                    isActive
                      ? 'bg-dark-400 text-accent shadow-[0_0_20px_rgba(132,204,22,0.4)]'
                      : 'text-light-dim hover:text-accent'
                  )
                }
              >
                <Icon className={cn(
                  "mr-3 h-5 w-5 flex-shrink-0 transition-colors duration-300",
                  "group-hover:text-accent"
                )} />
                {item.name}
              </NavLink>
            );
          })}
        </div>
      </nav>
    </aside>
  );
}