import React from 'react';
import { Download } from 'lucide-react';
import { Card3D } from '../components/ui/Card3D';
import { SalesOverview } from '../components/analytics/SalesOverview';
import { InventoryMetrics } from '../components/analytics/InventoryMetrics';
import { CustomerInsights } from '../components/analytics/CustomerInsights';
import { PredictiveAnalytics } from '../components/analytics/PredictiveAnalytics';
import { TrendAnalysis } from '../components/analytics/TrendAnalysis';

export function Analytics() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1>Analytics & Insights</h1>
        <button className="flex items-center space-x-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-light text-white hover:bg-indigo-700">
          <Download className="h-4 w-4" />
          <span>Export Report</span>
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <SalesOverview />
        <InventoryMetrics />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <TrendAnalysis />
        </div>
        <div>
          <CustomerInsights />
        </div>
      </div>

      <PredictiveAnalytics />
    </div>
  );
}