import React from 'react';
import { Search, Filter } from 'lucide-react';
import { Card3D } from '../ui/Card3D';

interface SearchBarProps {
  onSearch: (query: string) => void;
  onFilter: () => void;
}

export function SearchBar({ onSearch, onFilter }: SearchBarProps) {
  return (
    <Card3D className="p-4">
      <div className="flex items-center space-x-4">
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search products..."
            className="w-full rounded-md border-gray-300 pl-10 pr-4 py-2 focus:border-indigo-500 focus:ring-indigo-500"
            onChange={(e) => onSearch(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        <button 
          onClick={onFilter}
          className="flex items-center space-x-2 rounded-md border border-gray-300 px-4 py-2 text-sm font-light text-gray-700 hover:bg-gray-50"
        >
          <Filter className="h-4 w-4" />
          <span>Filter</span>
        </button>
      </div>
    </Card3D>
  );
}