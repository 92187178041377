import React from 'react';
import { UserPlus, Filter } from 'lucide-react';
import { Card3D } from '../components/ui/Card3D';
import { CustomerList } from '../components/customers/CustomerList';
import { CustomerStats } from '../components/customers/CustomerStats';
import { CustomerSegments } from '../components/customers/CustomerSegments';

export function Customers() {
  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1>Customer Management</h1>
        <button className="flex items-center space-x-2 rounded-md bg-indigo-600 px-4 py-2 text-sm font-light text-white hover:bg-indigo-700">
          <UserPlus className="h-4 w-4" />
          <span>Add Customer</span>
        </button>
      </div>

      <CustomerStats />

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
        <div className="lg:col-span-2">
          <Card3D className="p-6">
            <div className="flex items-center justify-between mb-6">
              <h2>Customer List</h2>
              <button className="flex items-center space-x-2 text-sm text-gray-600">
                <Filter className="h-4 w-4" />
                <span>Filter</span>
              </button>
            </div>
            <CustomerList />
          </Card3D>
        </div>
        <div>
          <CustomerSegments />
        </div>
      </div>
    </div>
  );
}